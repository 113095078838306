<template>
  <div>
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions"
        >
          <a-descriptions-item label="操作类型">
            {{ batchOperationRecordInfo.operation_type_display }}
          </a-descriptions-item>

          <a-descriptions-item label="操作批次">
            {{ batchOperationRecordInfo.operation_no }}
          </a-descriptions-item>

          <a-descriptions-item label="操作时间">
            {{ batchOperationRecordInfo.created_at }}
          </a-descriptions-item>

          <a-descriptions-item label="备注">
            <span class="batch-operation-record-info">
              {{ batchOperationRecordInfo.remark }}
            </span>
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions"
        >
          <a-descriptions-item label="订单编号">
            {{ batchOperationRecordInfo.agent_purchase_order_no }}
          </a-descriptions-item>

          <a-descriptions-item label="操作方式">
            {{ batchOperationRecordInfo.operator_type_display }}
          </a-descriptions-item>

          <a-descriptions-item label="操作人">
            {{ batchOperationRecordInfo.operator_name }}
          </a-descriptions-item>

          <a-descriptions-item label="重跑次数">
            {{ batchOperationRecordInfo.run_times }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="simiot-descriptions"
        >
          <a-descriptions-item label="项目编号">
            {{ batchOperationRecordInfo.agent_purchase_item_no }}
          </a-descriptions-item>

          <a-descriptions-item label="卡号总数">
            {{ batchOperationRecordInfo.operation_count }}
          </a-descriptions-item>

          <a-descriptions-item label="操作失败数">
            {{ batchOperationRecordInfo.failure_count }}
          </a-descriptions-item>

          <a-descriptions-item label="操作成功数">
            {{ batchOperationRecordInfo.success_count }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>

    <a-row>
      <a-col>
        <span>
          注：执行批量退卡入库操作时，如果有一张卡退卡入库失败，则后面将不再执行退卡入库操作，本次退卡入库的操作结果为失败。
        </span>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'BatchOperationDetailBackToInventoryInfo',
  props: {
    batchOperationRecordInfo: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
  .batch-operation-record-info {
    word-break: break-all;
  }
</style>

